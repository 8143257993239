<script src="../../router/index.js"></script>
<template>
  <div class="whiteList">
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="80px">
          <el-row :gutter="10">
            <!--            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
              <el-form-item label="Pb domain" class="mb0">
                <el-input
                  clearable
                  v-model.number="filterForm.domain"
                  placeholder="Please enter Pb domain"
                ></el-input>
              </el-form-item>
            </el-col>-->
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <el-form-item label="pid" prop="pid">
                <el-select
                  v-model="filterForm.pid"
                  filterable
                  clearable
                  placeholder="Please select"
                  class="w100"
                >
                  <el-option
                    v-for="item in options.pidList"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
              <el-form-item label-width="0" class="mb0">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search
                </el-button>
                <el-button type="primary" @click="openDialog('add')">Create</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width:100%;min-height: 70vmin;"
          stripe
          border
          :data="list"
          highlight-current-row
          aria-setsize="mini"
          size="mini"
          class="mb10"
        >
          <el-table-column label="id" prop="id" align="center"></el-table-column>
          <el-table-column label="pid" prop="pid" align="center"></el-table-column>
          <el-table-column label="click cap" prop="clickCap" align="center"></el-table-column>
          <el-table-column label="create time" prop="createTime" align="center"></el-table-column>
          <el-table-column label="update time" prop="updateTime" align="center"></el-table-column>
          <el-table-column label="Action" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="openDialog('edit', scope.row)"
                >Edit</el-button
              >
              <el-button type="primary" size="mini" @click="deletes(scope.row.id)"
                >Delete</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-dialog
          :title="dialog.title"
          :visible.sync="dialog.visible"
          width="30%"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          :show-close="false"
        >
          <el-form
            v-model="postData"
            size="small"
            :model="postData"
            ref="postDataForm"
            label-width="90px"
            :rules="rules"
            v-if="dialog.type !== 'off'"
          >
            <el-form-item label="pid" prop="pid">
              <el-select
                v-model="postData.pid"
                filterable
                collapse-tags
                placeholder="Please select"
                class="w100"
              >
                <el-option v-for="item in options.pidList" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <!--<el-form-item label="status" prop="status">
              <el-select v-model="postData.status" placeholder="Please select" class="w100">
                <el-option
                  v-for="item in options.status"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>-->
            <el-form-item label="click cap" prop="clickCap">
              <el-input
                v-model.number="postData.clickCap"
                placeholder="click cap , number"
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog('postDataForm')" size="mini">取 消</el-button>
            <el-button
              type="primary"
              size="mini"
              @click="submitDialog('postDataForm')"
              :loading="loading.submitBtn"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import { fetchPidClickCapPage, handlePidClickCapAdd, handlePidClickCapDelete } from 'api/setting';
  import { getPidsList } from 'api/product/rankSearch';
  import Pagination from '@/components/pagination';

  export default {
    /*name: 'adjustPBList',
  ...adjustPBCon,*/

    components: {
      Pagination,
    },
    data() {
      return {
        filterForm: {
          page: 1,
          pageSize: 20,
          pid: '',
        },
        total: 0,
        loading: {
          list: false,
        },
        list: [],
        postData: {
          pid: '',
          clickCap: 0,
        },
        options: {
          pidList: [],
        },
        dialog: {
          visible: false,
          title: '--',
          type: '',
        },
        rules: {
          clickCap: [
            { required: true, message: 'click cap不能为空' },
            { type: 'number', message: 'click cap必须为数字值' },
          ],
        },
      };
    },
    mounted() {
      this.getList();
      this.getPids();
    },
    methods: {
      getList(page) {
        if (page) {
          this.filterForm.page = page;
        }
        this.loading.list = true;
        fetchPidClickCapPage(this.filterForm)
          .then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.list = res.result;
              this.total = res.total;
            } else {
              this.$message.error(res.message);
            }
            this.loading.list = false;
          })
          .catch((e) => {
            this.loading.list = false;
            this.$message.error(e);
          });
      },
      getPids() {
        getPidsList().then((res) => {
          this.options.pidList = res.result;
        });
      },

      // 打开弹窗
      openDialog(type, row) {
        this.dialog.type = type;
        this.dialog.title = type == 'add' ? '添加' : '编辑';
        this.dialog.visible = true;
        this.postData = {};
        if (row) {
          this.postData = Object.assign({}, row);
        }
      },

      // 删除
      deletes(id) {
        this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            handlePidClickCapDelete(id).then((res) => {
              if (res.code == 200) {
                this.$message.success('删除成功！');
                this.getList();
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
      },
      // 提交弹窗
      submitDialog(formName) {
        console.log(formName);
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading.submitBtn = true;
            let alertMessage = '';
            if (this.dialog.type == 'add') {
              console.log('添加操作');
              alertMessage = '添加成功!';
            } else if (this.dialog.type == 'edit') {
              alertMessage = '修改成功!';
            }
            handlePidClickCapAdd(this.postData).then((res) => {
              if (res.code == 200) {
                this.$message.success(alertMessage);
                this.loading.submitBtn = false;
                this.closeDialog(formName);
                this.getList();
              } else {
                this.$message.error(res.message);
                this.loading.submitBtn = false;
              }
            });
          }
        });
      },
      // 关闭弹窗
      closeDialog(formName) {
        this.$refs[formName].resetFields();
        this.dialog.visible = false;
      },
      handleSizeChange(size) {
        this.filterForm.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.filterForm.page = currentPage;
        this.getList();
      },
    },
  };
</script>

<style></style>
